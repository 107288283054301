<template>
  <div id="main-wrapper" :style="co_IsMobile ? 'padding-top: 80px' : ''">
    <div ref="cc" class="container" id="container" :class="co_HeightClass">
      <div
        :class="co_SignUpClass"
        v-if="!co_IsMobile || (co_IsMobile && !isSignIn)"
      >
        <div class="col-12 text-center" id="signup-logo" v-if="!co_IsMobile">
          <img src="img/logo.png" class="logo" @click="m_goToHome" />
        </div>
        <form action="#">
          <h3 class="capitalize">Create {{ d_userLevel }} Account</h3>
          <div class="social-container">
            <a
              href="javascript:void(0)"
              @click.prevent="m_signInWithFacebook(false)"
              class="social"
            >
              <i class="ti-facebook"></i>
            </a>
            <a
              href="javascript:void(0)"
              @click.prevent="m_signInWithGoogle(false)"
              class="social"
            >
              <i class="ti-google"></i>
            </a>
            <a
              v-if="co_IsIOS"
              href="javascript:void(0)"
              @click.prevent="m_signInWithApple(false)"
              class="social"
            >
              <i class="ti-apple"></i>
            </a>
          </div>
          <span class="secondary-main-color"
            >or use your email for registration</span
          >
          <input v-model="r_email" type="email" placeholder="Email" />
          <input v-model="r_password" type="password" placeholder="Password" />
          <input
            v-model="r_confirmpassword"
            type="password"
            placeholder="Confirm Password"
          />
          <button class="btn-main-sign" @click.prevent="m_createAccount">
            Sign Up
          </button>
          <a @click="signUp()" v-if="co_IsMobile"
            >Sign in as {{ d_userLevel }}</a
          >
        </form>
      </div>
      <div
        :class="co_SignInClass"
        v-if="!co_IsMobile || (co_IsMobile && isSignIn)"
      >
        <div class="col-12 text-center" v-if="!co_IsMobile">
          <img src="img/logo.png" class="logo" @click="m_goToHome" />
        </div>
        <form action="#">
          <h3 v-if="co_IsMobile">Sign in as {{ d_userLevel }}</h3>
          <div class="social-container">
            <a
              href="javascript:void(0)"
              @click.prevent="m_signInWithFacebook(true)"
              class="social"
            >
              <i class="ti-facebook"></i>
            </a>
            <a
              href="javascript:void(0)"
              @click.prevent="m_signInWithGoogle(true)"
              class="social"
            >
              <i class="ti-google"></i>
            </a>
            <a
              v-if="co_IsIOS"
              href="javascript:void(0)"
              @click.prevent="m_signInWithApple(true)"
              class="social"
            >
              <i class="ti-apple"></i>
            </a>
          </div>
          <span class="secondary-main-color">or use your account</span>
          <input v-model="l_email" type="email" placeholder="Email" />
          <input v-model="l_password" type="password" placeholder="Password" />
          <b-link
            class="forgot-password-main"
            style="color: #647b9c"
            to="/forgotpassword"
            >Forgot your password?</b-link
          >
          <button
            class="btn-theme btn-main-sign"
            @click.prevent="m_signInWithEmail"
          >
            Sign In
          </button>
          <a @click="signUp()" v-if="co_IsMobile"
            >Sign up as {{ d_userLevel }}</a
          >
        </form>
      </div>
      <div class="overlay-container" v-if="!co_IsMobile">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>Welcome Back!</h1>
            <p>
              To keep connected with us please login with your personal info
            </p>
            <button @click="m_goToSignIn" class="ghost" id="signIn">
              Sign In As {{ d_userLevel }}
            </button>
          </div>
          <div class="overlay-panel overlay-right">
            <h1>Hello, Friend!</h1>
            <p>Enter your personal details and start journey with us</p>
            <button @click="m_goToSignUp" class="ghost" id="signUp">
              Sign Up as {{ d_userLevel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fb_auth,
  fb_googleAuthProvider,
  fb_facebookAuthProvider,
  fb_appleAuthProvider,
} from "@/lib-core/lib-firebase";
import { cfaSignIn, cfaSignInApple } from "capacitor-firebase-auth";
import { m_isMobile } from "../../lib-core/lib-helpers/lib-utils";
import { ios, android } from "platform-detect";
import { mapGetters } from "vuex";
import { Capacitor } from "@capacitor/core";

export default {
  name: "cmp-login",
  data() {
    return {
      l_email: "",
      l_password: "",
      r_email: "",
      r_password: "",
      r_confirmpassword: "",
      d_errorMessage: "",
      d_userLevel: "",
      isSignIn: true,
    };
  },
  created() {
    this.$store.commit("md_fire/mt_setLoading", true);
    fb_auth
      .getRedirectResult()
      .then(async (p_result) => {
        if (this.isAuthenticated) {
          this.$router.push({ path: "/" });
        } else if (p_result.user) {
          const user = this.gt_authUser;
          if (!user.emailVerified) {
            this.$router.push({ name: "home" });
          } else if (
            p_result.additionalUserInfo &&
            p_result.additionalUserInfo.isNewUser
          )
            this.$router.push({ name: "account" });
          else {
            this.$router.push({ name: "home" });
          }
        } else {
          this.$store.commit("md_auth/mt_setUser", null);
        }
      })
      .catch(function (p_error) {
        console.error(p_error);
      });
  },
  async mounted() {
    setTimeout(async () => {
      const l_tempRole = await this.$localforage.getItem("fs_tempRole");
      this.d_userLevel = l_tempRole;
      this.$store.commit("md_fire/mt_setLoading", false);
      if (l_tempRole != "tutor" && l_tempRole != "student") {
        this.m_route("as");
        return;
      }
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
  computed: {
    ...mapGetters("md_auth", ["gt_authUser"]),
    co_IsIOS() {
      return Capacitor.platform == "ios";
    },
    co_IsMobile() {
      return ios || android;
    },
    co_SignUpClass() {
      return this.co_IsMobile
        ? "sign-up-container-mobile form-container-mobile"
        : "sign-up-container form-container";
    },
    co_SignInClass() {
      return this.co_IsMobile
        ? "sign-in-container-mobile form-container-mobile"
        : "sign-in-container form-container";
    },
    co_HeightClass() {
      return this.co_IsMobile ? "heightInherit" : "";
    },
  },
  methods: {
    signUp() {
      this.isSignIn = !this.isSignIn;
    },
    m_route(p_name) {
      if (this.$route.name == p_name) return;
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: p_name });
    },
    m_signInWithApple(isSignin) {
      this.d_errorMessage = "";
      this.$store.commit("md_fire/mt_setLoading", true);
      //
      if (this.co_IsIOS) {
        cfaSignInApple().subscribe(
          async (p_user) => {
            this.$store.commit("md_auth/mt_setUser", p_user);

            const l_fromCheckout = await this.$localforage.getItem(
              "fs_fromCheckout"
            );
            if (isSignin) {
              if (l_fromCheckout) {
                this.$router.push({ name: "checkout" });
              } else {
                this.$router.push({ name: "home" });
              }
            } else {
              this.$router.push({ name: "account" });
            }
          },
          (p_error) => {
            this.$store.commit("md_fire/mt_setLoading", false);
          }
        );
      } else {
        fb_auth
          .signInWithRedirect(fb_appleAuthProvider)
          .then(async (p_user) => {
            this.$store.commit("md_auth/mt_setUser", p_user);

            const l_fromCheckout = await this.$localforage.getItem(
              "fs_fromCheckout"
            );
            if (isSignin) {
              if (l_fromCheckout) {
                this.$router.push({ name: "checkout" });
              } else {
                this.$router.push({ name: "home" });
              }
            } else {
              this.$router.push({ name: "account" });
            }
          })
          .catch((p_error) => {
            this.$store.commit("md_fire/mt_setLoading", false);
          });
      }
    },
    m_signInWithGoogle(isSignin) {
      this.d_errorMessage = "";
      this.$store.commit("md_fire/mt_setLoading", true);
      if (m_isMobile()) {
        cfaSignIn("google.com").subscribe(
          async (p_user) => {
            this.$store.commit("md_auth/mt_setUser", p_user);

            const l_fromCheckout = await this.$localforage.getItem(
              "fs_fromCheckout"
            );
            if (isSignin) {
              if (l_fromCheckout) {
                this.$router.push({ name: "checkout" });
              } else {
                this.$router.push({ name: "home" });
              }
            } else {
              this.$router.push({ name: "account" });
            }
          },
          (p_error) => {
            this.$store.commit("md_fire/mt_setLoading", false);
          }
        );
      } else {
        fb_auth
          .signInWithRedirect(fb_googleAuthProvider)
          .then(async (p_user) => {
            this.$store.commit("md_auth/mt_setUser", p_user);

            const l_fromCheckout = await this.$localforage.getItem(
              "fs_fromCheckout"
            );
            if (isSignin) {
              if (l_fromCheckout) {
                this.$router.push({ name: "checkout" });
              } else {
                this.$router.push({ name: "home" });
              }
            } else {
              this.$router.push({ name: "account" });
            }
          })
          .catch((p_error) => {
            this.$store.commit("md_fire/mt_setLoading", false);
          });
      }

      // fb_auth.signInWithRedirect(fb_googleAuthProvider).catch((p_error) => {
      //   this.m_onSignInError(p_error);
      //   this.$store.commit("md_fire/mt_setLoading", false);
      //   this.$store.commit("md_auth/ac_login", null);
      // });
    },
    m_signInWithFacebook(isSignin) {
      this.d_errorMessage = "";
      this.$store.commit("md_fire/mt_setLoading", true);
      if (m_isMobile()) {
        cfaSignIn("facebook.com").subscribe(
          async (p_user) => {
            this.$store.commit("md_auth/mt_setUser", p_user);
            const l_fromCheckout = await this.$localforage.getItem(
              "fs_fromCheckout"
            );
            if (isSignin) {
              if (l_fromCheckout) {
                this.$router.push({ name: "checkout" });
              } else {
                this.$router.push({ name: "home" });
              }
            } else {
              this.$router.push({ name: "account" });
            }
          },
          (p_error) => {
            this.$store.commit("md_fire/mt_setLoading", false);
          }
        );
      } else {
        fb_auth.signInWithRedirect(fb_facebookAuthProvider).then(
          async (p_user) => {
            this.$store.commit("md_auth/mt_setUser", p_user);

            const l_fromCheckout = await this.$localforage.getItem(
              "fs_fromCheckout"
            );
            if (isSignin) {
              if (l_fromCheckout) {
                this.$router.push({ name: "checkout" });
              } else {
                this.$router.push({ name: "home" });
              }
            } else {
              this.$router.push({ name: "account" });
            }
          },
          (p_error) => {
            // console.log(p_error)
            this.$store.commit("md_fire/mt_setLoading", false);
            // this.$store.commit("md_auth/ac_login", null);
          }
        );
      }
    },
    m_signInWithEmail() {
      this.d_errorMessage = "";
      if (
        this.l_email.trim().length == 0 ||
        this.l_password.trim().length == 0
      ) {
        this.d_errorMessage = "Please fill all the fields";
        this.m_showDialog(this.d_errorMessage);
        return;
      }
      if (!this.m_validateEmail(this.l_email)) {
        this.d_errorMessage = "Your email is not valid";
        this.m_showDialog(this.d_errorMessage);
        return;
      }
      this.$store.commit("md_fire/mt_setLoading", true);
      setTimeout(() => {
        fb_auth
          .signInWithEmailAndPassword(this.l_email, this.l_password)
          .then((user) => {
            // console.log(user);
            this.$store.commit("md_auth/mt_setUser", user);

            this.$router.push({ name: "home" });
          })
          .catch((p_error) => {
            setTimeout(() => {
              console.error(p_error);
              this.m_onSignInError(p_error);
              this.$store.commit("md_auth/mt_setUser", null);
              this.$store.commit("md_fire/mt_setLoading", false);
            }, 1000);
          });
      }, 1000);
    },
    m_createAccount() {
      if (
        this.r_email.trim().length == 0 ||
        this.r_password.trim().length == 0 ||
        this.r_confirmpassword.trim().length == 0
      ) {
        this.d_errorMessage = "Please fill all the fields";
        this.m_showDialog(this.d_errorMessage);

        return;
      }
      if (!this.m_validateEmail(this.r_email)) {
        this.d_errorMessage = "Invalid email";
        this.m_showDialog(this.d_errorMessage);

        return;
      }
      if (this.r_password !== this.r_confirmpassword) {
        this.d_errorMessage = "Passwords does not match";
        this.m_showDialog(this.d_errorMessage);

        return;
      }
      if (this.r_password.length <= 6) {
        this.d_errorMessage =
          "Your password should be longer than 6 characters";
        this.m_showDialog(this.d_errorMessage);
        return;
      }

      this.$store.commit("md_fire/mt_setLoading", true);

      fb_auth
        .createUserWithEmailAndPassword(this.r_email, this.r_password)
        .then((user) => {
          // console.log(user);
          this.$store.commit("md_auth/mt_setUser", user);

          this.$router.push({ name: "verify" });
        })
        .catch((p_error) => {
          setTimeout(() => {
            console.error(p_error);
            this.onSignupError(p_error);
            this.$store.commit("md_auth/mt_setUser", null);
            this.$store.commit("md_fire/mt_setLoading", false);
          }, 1000);
        });
    },
    m_goToSignIn() {
      this.r_email = "";
      this.r_password = "";
      this.r_confirmpassword = "";
      this.d_errorMessage = "";
      this.$refs.cc.classList.remove("right-panel-active");
      if (m_isMobile()) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    m_goToSignUp() {
      this.l_email = "";
      this.l_password = "";
      this.d_errorMessage = "";
      this.$refs["cc"].classList.add("right-panel-active");
      if (m_isMobile()) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    m_onSignInError(p_error) {
      if (p_error == null) return;

      switch (p_error.code) {
        case "auth/user-not-found":
          this.d_errorMessage = "Incorrect email or password";
          break;
        case "auth/wrong-password":
          this.d_errorMessage = "Incorrect email or password";
          break;
        case "auth/network-request-failed":
          this.d_errorMessage = "Check your internet connection and try again";
          break;
        case "auth/account-exists-with-different-credential":
          this.d_errorMessage =
            "Please sign in with this email with another provider (Google,Facebook,Hotmail)";
          break;
        default:
          this.d_errorMessage = "Sign in failed, please try again";
      }
      this.m_showDialog(this.d_errorMessage);
    },
    onSignupError(p_error) {
      //
      if (p_error == null) return;

      switch (p_error.code) {
        case "auth/email-already-exists":
          this.d_errorMessage = "Email already exists!";
          break;
        case "auth/email-already-in-use":
          this.d_errorMessage = "This email is already registered";
          break;
        case "auth/network-request-failed":
          this.d_errorMessage = "Check your internet connection and try again";
          break;
        default:
          this.d_errorMessage = "Sign up failed, please try again";
      }
      this.m_showDialog(this.d_errorMessage);
    },
    m_validateEmail(p_email) {
      const l_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return l_regex.test(String(p_email).toLowerCase());
    },
    m_forgotpassword() {
      this.$router.push({ name: "forgotPassword" });
    },
    m_showDialog(msg) {
      let l_payload = {
        message: msg,
        button: {
          yes: "Ok",
        },
      };
      this.$confirm(l_payload);
    },
    m_goToHome() {
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
}

.btn-main-sign {
  margin-top: 15px;
}
.capitalize {
  text-transform: capitalize;
}

body {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
}
.logo {
  cursor: pointer;
  width: 150px;
}

img {
  width: 25%;
  margin-bottom: 50px;
  margin-top: 50px;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border: 1px solid #83bf6f;
  background-color: #83bf6f;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
  margin-top: -150px;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  max-width: 400px;
}

.heightInherit {
  height: inherit !important;
}

.container {
  background-color: #fff;
  border-radius: 0px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.form-container-mobile {
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.sign-in-container-mobile {
  width: 100%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #83bf6f;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}

.overlay-panel h1 {
  color: #ffffff;
}

.main-title-header {
  margin-top: 50px;
  color: #e41515;
  width: 100vw;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  #signup-logo {
    display: none;
  }
  .main-title-header {
    margin-top: -10px;
    color: #e41515;
    width: 100vw;
    text-align: center;
  }
  img {
    width: 50%;
    margin-bottom: 15px;
  }
  .container {
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 550px;
  }
  .sign-up-container {
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }
  .form-container {
    position: absolute;
    top: 0;
    height: 70%;
    transition: all 0.6s ease-in-out;
  }
  form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 10px 30px;
    height: 100%;
    text-align: center;
    margin-top: auto;
  }
  .sign-in-container {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .overlay-container {
    left: 0;
    position: absolute;
    top: 70%;
    width: 100%;
    height: 30%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }
  .overlay {
    background: #83bf6f;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: 0;
    height: 200%;
    width: 100%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  .overlay-left {
    transform: translateY(110%) !important;
  }
  .overlay-right {
    bottom: 0;
    transform: translateY(0) !important;
  }
  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  h1 {
    line-height: 36px;
    font-size: 30px;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateY(43%);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show-data-v-5d395126 0.6s;
    animation: show-data-v-5d395126 0.6s;
  }
  .container.right-panel-active .sign-in-container {
    transform: translateY(100%);
    opacity: 0;
  }
  .container.right-panel-active .overlay-container {
    transform: translateY(-233%);
  }
  .container.right-panel-active .overlay {
    transform: translateX(0);
    transform: translateY(0);
  }
  .container.right-panel-active .overlay-left {
    transform: translateY(0) !important;
    transform: translateX(0);
  }
  .container.right-panel-active .overlay-right {
    transform: translateY(100%) !important;
    transform: translateX(0);
  }
  .overlay-panel p {
    margin: 0px 0 10px;
  }

  .overlay-right h1 {
    font-size: 20px;
    line-height: 26px;
  }
  .overlay-left h1 {
    font-size: 20px;
    line-height: 26px;
  }
  .social-container {
    margin: 5px 0;
  }
  .btn-main-sign {
    margin-top: 8px;
  }
  .forgot-password-main {
    padding: 0;
    margin: 0;
  }
}
</style>